<template>
  <BaseModal
    name="sales-export"
    size="lg"
    title="Exportação"
    @shown="openModal"
  >
    <b-row v-if="!loading">
      <b-col cols="12">
        <div class="top-dados">
          <div>
            <p>Selecione os dados para a importação.</p>
          </div>
        </div>
        <b-form novalidate>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Dados Principais"
                label-for="method"
                class="form-relative"
              >
                <a
                  href="#"
                  @click.prevent="selecionarTodos('dados')"
                  class="btn-todos"
                  >Todos os dados</a
                >
                <multiselect
                  v-model="dados"
                  placeholder="Dados"
                  label="name"
                  track-by="id"
                  :options="dice_list"
                  :multiple="true"
                  :taggable="false"
                  :clear-on-select="false"
                  :close-on-select="false"
                  class=""
                  selectLabel="Clique para selecionar"
                  deselectLabel="Clique para remover"
                  selectedLabel="✔️"
                  ><span slot="noResult"
                    >Nenhum método encontrado.</span
                  ></multiselect
                >
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-if="advanced_list.length">
            <b-col cols="12">
              <b-form-group
                label="Dados Avançados"
                label-for="method"
                class="form-relative"
              >
                <a
                  href="#"
                  @click.prevent="selecionarTodos('advanced')"
                  class="btn-todos"
                  >Todos os dados</a
                >
                <multiselect
                  v-model="dados2"
                  placeholder="Dados Avançados"
                  label="meta_key"
                  track-by="meta_key"
                  :options="advanced_list"
                  :multiple="true"
                  :taggable="false"
                  :clear-on-select="false"
                  :close-on-select="false"
                  class=""
                  selectLabel="Clique para selecionar"
                  deselectLabel="Clique para remover"
                  selectedLabel="✔️"
                  ><span slot="noResult"
                    >Nenhum método encontrado.</span
                  ></multiselect
                >
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group label="E-mail para notificar a Exportação">
                <b-form-input
                  v-model="email"
                  placeholder="E-mail"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="secondary_outline" class="mr-4" @click="cancel">
        Cancelar
      </BaseButton>
      <BaseButton variant="secondary" @click="onSubmit" :disabled="loading">
        Exportar
      </BaseButton>
    </template>

    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </BaseModal>
</template>
<script>
import Multiselect from "vue-multiselect";

import SaleService from "@/services/resources/SaleService";
const serviceSale = SaleService.build();

export default {
  props: ["filters"],
  components: {
    Multiselect,
  },
  data() {
    return {
      email: "",
      loading: false,
      dados: [],
      dados2: [],
      advanced_list: [],
      dice_list: [
        { id: "product_name", name: "Nome do Produto" },
        { id: "client_name", name: "Nome do Cliente" },
        { id: "client_email", name: "E-mail do Cliente" },
        { id: "id", name: "Código da Venda" },
        { id: "date", name: "Data" },
        { id: "amount", name: "Valor Bruto" },
        { id: "balance", name: "Valor Líquido" },
        { id: "client_cpf_cnpj", name: "CPF/CNPJ" },
        { id: "client_address", name: "Endereço do Cliente" },
        { id: "client_cellphone", name: "Telefone" },
        { id: "method", name: "Metodo de Pagamento" },
        { id: "status", name: "Status da Venda" },
        { id: "affiliate", name: "Total afiliado" },
        { id: "co_producer", name: "Total Co-Produtor" },
        { id: "pay_day", name: "Data de pagamento" },
      ],
    };
  },
  methods: {
    selecionarTodos(type) {
      switch (type) {
        case "dados": {
          this.dados = [
            { id: "product_name", name: "Nome do Produto" },
            { id: "client_name", name: "Nome do Cliente" },
            { id: "client_email", name: "E-mail do Cliente" },
            { id: "id", name: "Código da Venda" },
            { id: "date", name: "Data" },
            { id: "amount", name: "Valor Bruto" },
            { id: "balance", name: "Valor Líquido" },
            { id: "client_cpf_cnpj", name: "CPF/CNPJ" },
            { id: "client_address", name: "Endereço do Cliente" },
            { id: "client_cellphone", name: "Telefone" },
            { id: "method", name: "Metodo de Pagamento" },
            { id: "status", name: "Status da Venda" },
            { id: "affiliate", name: "Total afiliado" },
            { id: "co_producer", name: "Total Co-Produtor" },
            { id: "pay_day", name: "Data de pagamento" },
          ];
          break;
        }
        case "advanced": {
          this.dados2 = this.advanced_list;
          break;
        }
      }
    },
    onSubmit() {
      this.loading = true;

      let basic = [];
      let dados = this.dados;
      for (let i = 0; i < dados.length; i++) {
        basic.push(dados[i].id);
      }
      let advanced = [];
      let dados2 = this.dados2;
      for (let i = 0; i < dados2.length; i++) {
        advanced.push(dados2[i].meta_key);
      }

      let data = {
        id: "/export",
        basic: basic,
        metas: advanced,
        email: this.email,
        filters: JSON.stringify(this.filters),
      };

      serviceSale
        .createId(data)
        .then(() => {
          this.$bvToast.toast(
            "Sua exportação está sendo processada, em instantes você receberá um e-mail com os dados.",
            {
              title: "Exportação",
              variant: "info",
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
          this.$bvModal.hide("sales-export");
        })
        .catch((err) => {
          console.log(err);
          this.$bvToast.toast("Sua exportação não foi processada!", {
            title: "Exportação",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.$bvModal.hide("sales-export");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchAdicionais() {
      this.loading = true;
      serviceSale
        .read("/meta/keys")
        .then((response) => {
          this.advanced_list = response;
        })
        .catch((err) => {
          console.log(err);
          this.products = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openModal() {
      this.dados = [];
      this.dados2 = [];
      this.email = this.$store.getters.getProfile.email;
      this.fetchAdicionais();
    },
  },
  mounted() {},
};
</script>

<style scoped>
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
p + p {
  margin-top: 5px;
}
.link-documentacao {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}
.top-dados {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .top-dados {
    display: block;
  }
  .top-dados #limpar {
    margin-top: 15px;
  }
}
.form-relative {
  position: relative;
}
.btn-todos {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: #81858e;
}
</style>
