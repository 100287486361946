<template>
  <BaseModal name="sales-filters" size="lg" title="Filtros" @shown="openModal">
    <b-row>
      <b-col cols="12">
        <div class="top-filter">
          <div>
            <p>Os seus filtros ficam aqui.</p>
            <p v-if="contemFilterLocal">
              Sempre salvados os filtros que utilizou para facilitar sua busca
            </p>
          </div>
          <BaseButton
            variant="info3"
            class="link-documentacao"
            id="limpar"
            @click="limparLocal"
            :disabled="!contemFilterLocal"
            >Limpar filtros</BaseButton
          >
        </div>
        <b-form novalidate>
          <b-row>
            <b-col>
              <b-form-group label="Produtos">
                <multiselect
                  v-model="filter.products"
                  id="ajax"
                  label="name"
                  track-by="id"
                  placeholder="Pesquise o Produto"
                  selectLabel="Clique para selecionar"
                  deselectLabel="Clique para remover"
                  selectedLabel="✔️"
                  open-direction="bottom"
                  :options="products"
                  :multiple="true"
                  :searchable="true"
                  :loading="loading"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="false"
                  :options-limit="10"
                  :show-no-results="true"
                  :hide-selected="true"
                  @search-change="aux_product_debounce"
                >
                  <span slot="noOptions">Pesquise pelo produto</span>

                  <span slot="noResult"
                    >Oops! Nenhum produto encontrado. Pesquise outro termo</span
                  >
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group label="Afiliados">
                <multiselect
                  v-model="filter.affiliates"
                  placeholder="Selecione o Afiliado"
                  label="name"
                  track-by="id"
                  :options="affiliates"
                  :multiple="true"
                  :taggable="false"
                  class=""
                  selectLabel=""
                  deselectLabel=""
                  selectedLabel="✔️"
                  ><span slot="noResult"
                    >Nenhum afiliado encontrado.</span
                  ></multiselect
                >
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group label="Contratos" label-for="contracts">
                <multiselect
                  v-model="filter.contracts"
                  id="ajax"
                  label="name"
                  track-by="id"
                  placeholder="Pesquise o ID do Contrato"
                  selectLabel="Clique para selecionar"
                  deselectLabel="Clique para remover"
                  selectedLabel="✔️"
                  open-direction="bottom"
                  :options="contracts"
                  :multiple="true"
                  :searchable="true"
                  :loading="loading_contract"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="false"
                  :options-limit="10"
                  :show-no-results="true"
                  :hide-selected="true"
                  @search-change="aux_contract_debounce"
                >
                  <span slot="noOptions">Pesquise pelo ID do contrato</span>

                  <span slot="noResult">Digite outro ID do contrato</span>
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Método de pagamento"
                label-for="method"
                class="form-relative"
              >
                <a
                  href="#"
                  @click.prevent="selecionarTodos('metodos')"
                  class="btn-todos"
                  >Todos os métodos</a
                >
                <multiselect
                  v-model="filter.method"
                  placeholder="Método de pagamento "
                  label="name"
                  track-by="id"
                  :options="method_list"
                  :multiple="true"
                  :taggable="false"
                  :clear-on-select="false"
                  :close-on-select="false"
                  class=""
                  selectLabel="Clique para selecionar"
                  deselectLabel="Clique para remover"
                  selectedLabel="✔️"
                  ><span slot="noResult"
                    >Nenhum método encontrado.</span
                  ></multiselect
                >
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Status"
                label-for="status"
                class="form-relative"
              >
                <a
                  href="#"
                  @click.prevent="selecionarTodos('status')"
                  class="btn-todos"
                  >Todos os status</a
                >
                <multiselect
                  v-model="filter.status"
                  placeholder="Status "
                  label="name"
                  track-by="id"
                  :options="status_list"
                  :multiple="true"
                  :taggable="false"
                  :clear-on-select="false"
                  :close-on-select="false"
                  selectLabel="Clique para selecionar"
                  deselectLabel="Clique para remover"
                  selectedLabel="✔️"
                  ><span slot="noResult"
                    >Nenhum status encontrado.</span
                  ></multiselect
                >
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="6">
              <b-form-group label="Nome da meta tag" label-for="meta_key">
                <b-form-input
                  id="meta_key"
                  v-model="filter.meta_key"
                  type="text"
                  placeholder="utm_source"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Valor da meta tag" label-for="meta_value">
                <b-form-input
                  id="meta_value"
                  v-model="filter.meta_value"
                  type="text"
                  placeholder="google"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="secondary_outline" class="mr-4" @click="cancel">
        Cancelar
      </BaseButton>
      <BaseButton variant="secondary" @click="onSubmit"> Filtrar </BaseButton>
    </template>
  </BaseModal>
</template>
<script>
import Multiselect from "vue-multiselect";
import _ from "lodash";

import ProductService from "@/services/resources/ProductService";
const serviceProduct = ProductService.build();

import ContractService from "@/services/resources/ContractService";
const serviceContract = ContractService.build();

import SaleService from "@/services/resources/SaleService";
const serviceSale = SaleService.build();

export default {
  props: {
    value: {
      type: Number,
    },
    // pre_filter: {
    //   type: Object,
    //   default() {
    //     return {};
    //   },
    // },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      pre_filter: {},
      contemFilterLocal: false,
      search: "",
      loading: false,
      loading_contract: false,
      affiliates: [],

      filter: {
        affiliates: [],

        status: [
          {
            id: "paid",
            name: "Pago",
          },
          {
            id: "waiting_payment",
            name: "Aguardando pagamento",
          },
        ],
        method: [
          { id: "CREDIT_CARD", name: "Cartão de crédito" },
          { id: "BOLETO", name: "Boleto" },
          { id: "PIX", name: "Pix" },
          { id: "PAYPAL", name: "PayPal" },
          { id: "TWO_CREDIT_CARDS", name: "Dois Cartões" },
        ],
        meta_key: null,
        meta_value: null,
        products: [],
        contracts: [],
      },
      products: [],
      contracts: [],
      types_list: [
        { value: "created_at", text: "Criação" },
        { value: "paid_at", text: "Pagamento" },
      ],

      status_list: [
        {
          id: "processing",
          name: "Processando",
        },
        {
          id: "authorized",
          name: "Transação autorizada",
        },
        {
          id: "paid",
          name: "Pago",
        },

        {
          id: "refunded",
          name: "Estornado",
        },
        {
          id: "waiting_payment",
          name: "Aguardando pagamento",
        },

        {
          id: "refund_pending",
          name: "Reembolso pendente",
        },
        {
          id: "refused",
          name: "Rejeitado",
        },

        {
          id: "chargedback",
          name: "ChargeBack",
        },
        {
          id: "delayed",
          name: "Em processamento",
        },
      ],

      method_list: [
        { id: "CREDIT_CARD", name: "Cartão de crédito" },
        { id: "BOLETO", name: "Boleto" },
        { id: "PIX", name: "Pix" },
        { id: "PAYPAL", name: "PayPal" },
        { id: "TWO_CREDIT_CARDS", name: "Dois Cartões" },
      ],
    };
  },

  methods: {
    selecionarTodos(type) {
      switch (type) {
        case "metodos": {
          this.filter.method = [
            { id: "CREDIT_CARD", name: "Cartão de crédito" },
            { id: "BOLETO", name: "Boleto" },
            { id: "PIX", name: "Pix" },
            { id: "PAYPAL", name: "PayPal" },
            { id: "TWO_CREDIT_CARDS", name: "Dois Cartões" },
          ];
          break;
        }
        case "status": {
          this.filter.status = [
            {
              id: "processing",
              name: "Processando",
            },
            {
              id: "authorized",
              name: "Transação autorizada",
            },
            {
              id: "paid",
              name: "Pago",
            },

            {
              id: "refunded",
              name: "Estornado",
            },
            {
              id: "waiting_payment",
              name: "Aguardando pagamento",
            },

            {
              id: "refund_pending",
              name: "Reembolso pendente",
            },
            {
              id: "refused",
              name: "Rejeitado",
            },

            {
              id: "chargedback",
              name: "ChargeBack",
            },
            {
              id: "delayed",
              name: "Em processamento",
            },
          ];
          break;
        }
      }
    },
    // produto
    aux_product_debounce(query) {
      this.loading = true;
      this.search = query;
      this.debounce_product();
    },
    debounce_product: _.debounce(function() {
      this.fetchProducts(this.search);
    }, 500),

    // contrato
    aux_contract_debounce(query) {
      this.loading_contract = true;
      this.search = query;
      this.debounce_contract();
    },
    debounce_contract: _.debounce(function() {
      this.fetchContract(this.search);
    }, 500),

    onSubmit() {
      window.localStorage.sales_filters_aux = JSON.stringify({
        status: this.filter.status,
        method: this.filter.method,
        meta_key: this.filter.meta_key,
        meta_value: this.filter.meta_value,
        products: this.filter.products,
        contracts: this.filter.contracts,
        affiliates: this.filter.affiliates,
      });
      let data = {
        status: this.filter.status.map((item) => item.id),
        method: this.filter.method.map((item) => item.id),
        meta_key: this.filter.meta_key,
        meta_value: this.filter.meta_value,
      };
      if (this.filter.affiliates.length) {
        data.affiliates = this.filter.affiliates.map((item) => item.id);
      }
      if (this.filter.products.length) {
        data.products = this.filter.products.map((item) => item.id);
      }

      if (this.filter.contracts.length) {
        data.contracts = this.filter.contracts.map((item) => item.id);
      }

      this.$emit("filter", data);
      this.$bvModal.hide("sales-filters");
    },
    fetchProducts(search = null) {
      let data = {
        list: true,
      };

      if (search) data.search = search;

      this.products = [];

      serviceProduct
        .search({ ...data, with_participants: true })
        .then((response) => {
          response.forEach((element) => {
            this.products.push({ id: element.id, name: element.name });
          });
        })
        .catch((err) => {
          console.log(err);
          this.products = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },

    fetchContract(search = null) {
      let data = {
        // list: true,
        id: search,
      };

      // if (search) data.search = search;

      this.contracts = [];

      serviceContract
        // .search(data)
        .read(data)
        .then((response) => {
          if (response.data) {
            response.data.data.forEach((element) => {
              this.contracts.push({
                id: element.id,
                name: `${element.contract.live.product.name} | ${element.client.name} `,
              });
            });
          } else {
            this.contracts.push({
              id: response.id,
              name: `${response.contract.live.product.name} | ${response.client.name} `,
            });
          }
        })
        .catch((err) => {
          this.contracts = [];
          console.log(err);
        })
        .finally(() => {
          this.loading_contract = false;
        });
    },
    fetchAffiliates() {
      let data = {
        id: "/affiliate",
      };

      this.affiliates = [];

      serviceSale
        .read(data)
        .then((response) => {
          response.forEach((element) => {
            this.affiliates.push({
              id: element.id,
              name: `${element.name}  - ${element.email}`,
            });
          });
        })
        .catch((err) => {
          console.log(err);
          this.affiliates = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    limparLocal() {
      window.localStorage.removeItem("sales_filters_aux");
      window.localStorage.removeItem("sales_filters");
      this.filter = {
        status: [
          {
            id: "paid",
            name: "Pago",
          },
          {
            id: "waiting_payment",
            name: "Aguardando pagamento",
          },
        ],
        method: [
          { id: "CREDIT_CARD", name: "Cartão de crédito" },
          { id: "BOLETO", name: "Boleto" },
          { id: "PIX", name: "Pix" },
          { id: "PAYPAL", name: "PayPal" },
          { id: "TWO_CREDIT_CARDS", name: "Dois Cartões" },
        ],
        meta_key: null,
        meta_value: null,
        products: [],
        contracts: [],
        affiliates: [],
      };
      this.products = [];
      this.contracts = [];
      this.affiliates = [];
      this.verificaLocal();
    },
    verificaLocal() {
      if (window.localStorage.sales_filters_aux) {
        this.contemFilterLocal = true;
      } else {
        this.contemFilterLocal = false;
      }
    },

    openModal() {
      this.verificaLocal();
      if (window.localStorage.sales_filters_aux) {
        this.pre_filter = JSON.parse(window.localStorage.sales_filters_aux);
        this.filter = this.pre_filter;
      }
    },
  },
  mounted() {
    this.fetchAffiliates();
  },
  created() {
    // this.fetchProducts();
  },
};
</script>

<style scoped>
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
p + p {
  margin-top: 5px;
}
.link-documentacao {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}
.top-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .top-filter {
    display: block;
  }
  .top-filter #limpar {
    margin-top: 15px;
  }
}
.form-relative {
  position: relative;
}
.btn-todos {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: #81858e;
}
</style>
