<template>
  <div class="container">
    <BaseHeader title="Minhas Vendas"> </BaseHeader>

    <div class="container-table">
      <div class="box-total">
        <p class="total-vendas info">⚡️ {{ itens }} Itens</p>
        <div>
          <p class="total-vendas">
            Total de Vendas
            <span>R$ {{ total ? formatMoney(total) : "0,00" }}</span>
          </p>
        </div>
        <div></div>
      </div>

      <div class="header-table">
        <div class="flex-sale">
          <div class="d-flex align-items-center">
            <b-tooltip
              target="filtro-1"
              title="Aplicar Filtro"
              placement="topright"
            />
            <b-button
              id="filtro-1"
              class="btn-table"
              @click="openFilters"
              title="Filtros"
              ><img src="@/assets/img/icons/filtro.svg"
            /></b-button>

            <b-tooltip
              target="exportar-1"
              title="Exportar Dados"
              placement="topright"
            />
            <b-button
              @click="exportData"
              class="btn-table"
              title="Exportar dados"
              id="exportar-1"
              ><img src="@/assets/img/icons/download.svg" alt=""
            /></b-button>
          </div>

          <div class="d-flex align-items-center flex-acoes">
            <div class="datas">
              <date-range-picker
                ref="picker"
                opens="right"
                :locale-data="localeDate"
                :showWeekNumbers="false"
                :showDropdowns="true"
                :autoApply="false"
                :ranges="dateRanges"
                @update="fetchSales(search)"
                v-model="dateRange"
              >
                <template
                  v-if="dateRange.startDate && dateRange.endDate"
                  v-slot:input="picker"
                  style="min-width: 350px"
                >
                  {{ picker.startDate | date }} - {{ picker.endDate | date }}
                </template>
                <template
                  v-else
                  v-slot:input
                  class="btn-table"
                  title="Selecionar datas"
                >
                  Selecione as datas
                </template>
              </date-range-picker>
            </div>
            <div>
              <b-form-group label-for="type">
                <b-form-select
                  id="type"
                  v-model="type_date"
                  @change="changedType"
                  :options="types_list"
                ></b-form-select>
              </b-form-group>
            </div>
          </div>
        </div>

        <b-col cols="12" md="2" class="btn-estatistica">
          <button
            @click="updateStatusAndFetchReports"
            :class="`button button--${buttonReportStatus}`"
          >
            Estatísticas
            <img
              src="@/assets/img/icons/report-white.png"
              :class="`inverted--${buttonReportStatus}`"
              class="Pointer"
              style="margin-left: 29px; height: 16px; width: 12px"
            />
          </button>
        </b-col>

        <b-col cols="12" md="4" class="container-pesquisa">
          <div class="inputSearch">
            <img src="@/assets/img/icons/search.svg" class="Pointer search" />
            <input
              type="text"
              placeholder="Código da venda ou produto"
              v-on:input="debounce"
              v-model="search"
              class="input-busca"
            />
          </div>
        </b-col>
      </div>

      <div v-if="buttonReportStatus === 'black'" data-anima="right">
        <b-col cols="12">
          <h3 class="title-report" style="margin-bottom: 20px">Receitas</h3>
        </b-col>
        <div class="grid-report">
          <template v-for="report in earnReport">
            <div :key="report.title">
              <report-item
                :payload="report"
                :loading="loading_reports"
                :error="errorReport"
                :key="report.name"
              />
            </div>
          </template>
        </div>
      </div>

      <div v-if="buttonReportStatus === 'black'" data-anima="left" class="mb-3">
        <b-col cols="12">
          <h3
            class="title-report title-report-margin-top"
            style="margin-bottom: 20px"
          >
            Faturas
          </h3>
        </b-col>
        <div class="grid-report">
          <template v-for="report in invoiceReport">
            <div :key="report.title">
              <report-item
                :payload="report"
                :loading="loading_reports"
                :error="errorReport"
                :key="report.name"
              />
            </div>
          </template>
        </div>
      </div>

      <!-- HEADER -->
      <b-row class="Table-header mb-1">
        <b-col cols="1" class="d-none d-md-block"> # Fatura </b-col>

        <b-col cols="5" md="2" class=""> Produto </b-col>

        <b-col cols="2" class="d-none d-md-block"> Cliente </b-col>

        <b-col cols="2" class="d-none d-md-block">Dt. Criação </b-col>

        <b-col cols="2" class="d-none d-md-block">Dt. Pagamento </b-col>

        <!-- <b-col cols="1" class="d-none d-md-block"> Metas </b-col> -->

        <b-col cols="4" md="2" class=""> Vl. Líquido </b-col>

        <b-col cols="3" md="1" class=""> Visualizar </b-col>
      </b-row>
      <!-- END HEADER -->

      <!-- BODY -->
      <template v-if="!loading && sales.length > 0">
        <b-row
          class="Table-body"
          v-for="item in sales"
          :key="item.id"
          :class="getStatus(item.status).class"
        >
          <b-col
            cols="1"
            class="d-none d-md-block"
            id="id_venda"
            v-b-tooltip="{
              title: getStatus(item.status).text,
              placement: 'bottom',
            }"
          >
            # {{ item.id }}
          </b-col>

          <template v-if="!item.proposal">
            <b-col cols="5" md="2" class="">
              <div
                class="Table-body-title"
                v-if="item.product"
                v-b-tooltip="{
                  title: setName(item),
                  placement: 'top',
                }"
              >
                {{
                  setName(item) && setName(item).length > maxLength
                    ? setName(item).substr(0, maxLength) + "..."
                    : setName(item)
                }}
                <div class="offer-name">
                  {{ item.offer ? 'Oferta: '+item.offer.name : '' }}
                </div>
              </div>
              <div
                class="Table-body-title"
                v-else
                v-b-tooltip="{
                  title: setName(item),
                  placement: 'top',
                }"
              >
                {{
                  setName(item) && setName(item).length > maxLength
                    ? setName(item).substr(0, maxLength) + "..."
                    : setName(item)
                }}
                <div class="offer-name">
                  {{ item.offer ? 'Oferta: '+item.offer.name : '' }}
                </div>
              </div>
            </b-col>
          </template>

          <template v-if="item.proposal">
            <b-col cols="3" md="2" class="">
              <div
                class="Table-body-title"
                v-b-tooltip="{
                  title: item.proposal.name,
                  placement: 'top',
                }"
              >
                {{
                  item.proposal.name && item.proposal.name.length > maxLength
                    ? item.proposal.name.substr(0, maxLength) + "..."
                    : item.proposal.name
                }}
              </div>
            </b-col>
          </template>

          <b-col
            cols="2"
            class="d-none d-md-block dados-cliente"
            v-if="item.client"
          >
            <div>
              {{
                item.client.name && item.client.name.length > maxLength
                  ? item.client.name.substr(0, maxLength) + "..."
                  : item.client.name
              }}
            </div>

            <div
              v-if="item.client"
              :id="item.client.email"
              v-b-tooltip="{
                title: item.client.email,
                placement: 'top',
              }"
            >
              {{
                item.client.email && item.client.email.length > 20
                  ? item.client.email.substr(0, 20) + "..."
                  : item.client.email
              }}
            </div>

            <div>
              <a
                :href="formatTel(item.client.cellphone)"
                target="_blank"
                class="whats-cliente"
                ><font-awesome-icon :icon="['fab', 'whatsapp']" />{{
                  item.client.cellphone
                }}</a
              >
            </div>
          </b-col>
          <b-col
            cols="2"
            class="d-none d-md-block dados-cliente"
            v-if="!item.client"
          >
          </b-col>

          <b-col cols="2" class="d-none d-md-block" v-if="item.created_at">
            {{ item.created_at | datetime }}
          </b-col>

          <b-col cols="2" class="d-none d-md-block" v-if="item.paid_at">
            {{ item.paid_at | datetime }}
          </b-col>
          <b-col
            cols="2"
            class="d-none d-md-block"
            v-if="!item.paid_at"
          ></b-col>
          <!-- mantendo a div para nao quebrar -->
          <!-- <b-col cols="1" class="d-none d-md-block" v-else> </b-col>
          <b-col cols="1" class="d-none d-md-block">
            <ul class="metas p-0 m-0">
              <li v-for="meta in item.metas" :key="meta.id">
                <b v-if="meta.meta_key != 'utm_source'">{{ meta.meta_key }}:</b>
                {{ meta.meta_value }}
              </li>
            </ul>
          </b-col> -->

          <b-col cols="4" md="2" class="">
            <!-- {{ item.method }} -->
            <span class="flex">
              <font-awesome-icon
                v-if="item.method != 'TWO_CREDIT_CARDS'"
                :icon="getIconMethod(item.method)"
                class="icon-gray"
                :id="`method-icon-${item.id}`"
              />
              <b-tooltip
                :target="`method-icon-${item.id}`"
                :title="getMethod(item.method)"
                placement="left"
              />
              <font-awesome-icon
                v-if="item.method == 'TWO_CREDIT_CARDS'"
                icon="credit-card"
                class="icon-gray"
                :id="`method-icon-${item.id}`"
              />
              <font-awesome-icon
                v-if="item.method == 'TWO_CREDIT_CARDS'"
                icon="credit-card"
                class="icon-gray TWO_CREDIT_CARDS"
              />

              <font-awesome-icon
                v-if="item.bump_id"
                icon="donate"
                class="icon-gray ml-2"
                :id="`bump-sale-${item.id}`"
              />

              <b-tooltip
                v-if="item.bump_id"
                :target="`bump-sale-${item.id}`"
                title="ORDER BUMP"
                placement="left"
              />

              <font-awesome-icon
                v-if="item.coupon_id"
                icon="receipt"
                class="icon-gray ml-2"
                :id="`cupom-sale-${item.id}`"
              />
              <b-tooltip
                v-if="item.coupon_id"
                :target="`cupom-sale-${item.id}`"
                title="CUPOM DE DESCONTO"
                placement="left"
              />
            </span>
            <!-- :icon="item.method == 'BOLETO' ? 'barcode' : 'credit-card'" -->
            <!-- <span
              v-if="item.seller_id == userID && item.balance"
              class="valor-venda"
            > -->
            <span v-if="item.balance" class="valor-venda">
              R$ {{ formatMoney(+item.balance) }}
            </span>
            <span v-else class="valor-venda"> R$ 0,00 </span>
          </b-col>

          <b-col cols="3" md="1">
            <div class="Table-body-action" @click="openPanel(item.id)">
              <img src="@/assets/img/icons/eye.svg" />
            </div>
          </b-col>
        </b-row>
      </template>

      <b-row>
        <b-col>
          <Paginate
            v-if="sales.length > 0 && !initExport"
            :totalPages="pagination.totalPages"
            :activePage="pagination.currentPage"
            @to-page="toPage"
            @per-page="perPage"
          />
        </b-col>
      </b-row>

      <div
        v-if="(loading && !sales.length) || (loading && initExport)"
        class="Table-body py-4 d-flex justify-content-center align-items-center"
      >
        <b-spinner variant="dark" label="Spinning"></b-spinner>
      </div>

      <b-row
        v-if="!loading && !sales.length"
        class="Table-body justify-content-center"
      >
        <p class="nao-encontrado">Nenhuma venda foi encontrada</p>
      </b-row>

      <!-- END BODY -->

      <!-- modals -->
      <FiltersModal @filter="filter" />
      <ExportModal :filters="exportFilter" />
      <SalePreview ref="salePreview" />
    </div>
    <!--<CallEvaluation /> -->
  </div>
</template>
<script>
import SaleService from "@/services/resources/SaleService";
import Paginate from "@/components/shared/Paginate";
import FiltersModal from "@/components/Seller/Sales/modals/filters";
import ExportModal from "@/components/Seller/Sales/modals/export";
import DateRangePicker from "vue2-daterange-picker";
import Money from "@/mixins/money";
import moment from "moment-timezone";
import SalePreview from "@/components/shared/SalePreview";
import _ from "lodash";
import { textMethod } from "@/helpers.js";
import { iconMethod } from "@/helpers.js";
import Cookies from "js-cookie";
import ReportItem from "@/components/Seller/Sales/ReportItem";

const service = SaleService.build();

export default {
  name: "ListagemVendas",
  components: {
    Paginate,
    FiltersModal,
    ExportModal,
    DateRangePicker,
    SalePreview,
    ReportItem,
  },
  data() {
    return {
      earnReport: 4,
      invoiceReport: 6,
      errorReport: false,
      buttonReportStatus: "white",
      exportFilter: [],
      initExport: false,
      sales: [],
      sale_id: -1,
      search: null,
      loading: true,
      loading_reports: true,
      total: 0,
      itens: 0,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
      },
      filters: {},
      type_date: "created_at",
      types_list: [
        { value: "created_at", text: "Data de criação" },
        { value: "paid_at", text: "Data de pagamento" },
      ],
      localeDate: {
        direction: "ltr",
        format: "dd/mm/yyyy",
        separator: " - ",
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
        monthNames: [
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Mai",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez",
        ],
        firstDay: 0,
      },
      dateRange: {
        startDate: moment(),
        endDate: moment(),
      },
      columns: [
        {
          key: "id",
          label: "Fatura",
        },
        {
          key: "product",
          label: "Produto",
        },
        {
          key: "client",
          label: "Cliente",
        },
        {
          key: "created_at",
          label: "Data de criação",
        },
        {
          key: "paid_at",
          label: "Data de Pagamento",
        },
        {
          key: "metas",
          label: "Metas",
        },
        {
          key: "balance",
          label: "Valor líquido",
        },
        {
          key: "_visualize",
          label: "Visualizar",
        },
      ],

      client: {
        width: 0,
      },
    };
  },
  mixins: [Money],

  computed: {
    active() {
      return this.$route.name;
    },
    isSeller() {
      return this.$store.getters.isSeller;
    },
    userID() {
      return this.$store.getters.getProfile.id;
    },
    maxLength() {
      return this.$store.getters["sidebarState"] ? 20 : 27;
    },
    dateRanges() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      let lastWeek = new Date();
      lastWeek.setDate(today.getDate() - 7);
      lastWeek.setHours(0, 0, 0, 0);

      return {
        Hoje: [today, today],
        Ontem: [yesterday, yesterday],
        "Última semana": [lastWeek, today],
        "Este mês": [
          new Date(today.getFullYear(), today.getMonth(), 1),
          new Date(today.getFullYear(), today.getMonth(), 30),
        ],
        "Este ano": [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31),
        ],
        "Último mês": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        "Todo período": [
          new Date(2020, 0, 1),
          new Date(today.getFullYear(), today.getMonth(), today.getDate()),
        ],
      };
    },
  },

  methods: {
    setName(item){
      if(item.product){
        // if(item.offer){
        //   return item.offer.name;
        // }else{
          return item.product.name
        // }
      }else{
        // if(item.offer){
        //   return item.invoices[0].offer ? item.invoices[0].offer.name : item.invoices[0].product.name;
        // }else{
          return item.invoices[0].product.name
        // }
      }
    },
    updateStatusAndFetchReports() {
      if (this.buttonReportStatus === "white") {
        this.buttonReportStatus = "black";
        this.fetchReports(this.search);
      } else if (this.buttonReportStatus === "black") {
        this.buttonReportStatus = "white";
      }
    },
    fetchReports(search = null, exportData = false, dados_local = null) {
      this.loading_reports = true;

      let data = {};
      if (dados_local) {
        data = dados_local;
      } else {
        data = {
          page: this.pagination.currentPage,
          per_page: this.pagination.perPage,
          type: this.type_date,
          status: ["paid", "waiting_payment"],
        };
      }

      if (search != null && typeof search == "string" && search.length > 0) {
        data.search = search;
      }

      if (this.dateRange.startDate != null && this.dateRange.endDate != null) {
        data.date_start = moment
          .tz(this.dateRange.startDate, "America/Sao_Paulo")
          .format("YYYY-MM-DDT00:00:00-03:00");
        data.date_end = moment
          .tz(this.dateRange.endDate, "America/Sao_Paulo")
          .format("YYYY-MM-DDT23:59:59-03:00");
      }

      Object.keys(this.filters).forEach(
        (key) =>
          (this.filters[key] == null || this.filters[key].length === 0) &&
          delete this.filters[key]
      );
      data = Object.assign(data, this.filters);

      this.exportFilter = data;

      service
        .search({ ...data, url: "/report" })
        .then((response) => {
          const typeEarns = [
            {
              name: "Total de vendas",
              slug_income: "total_sales",
              icon: "moneyReport",
              icon_color: "blue",
              coin: "R$ ",
            },
            {
              name: "Ganho líquido",
              slug_income: "total_balance",
              icon: "earnUp",
              icon_color: "green",
              coin: "R$ ",
            },
            {
              name: "Reembolso",
              slug_income: "total_refund",
              icon: "earnDown",
              icon_color: "red",
              coin: "R$ ",
            },
            {
              name: "Chargeback",
              slug_income: "total_chargedback",
              icon: "cardReport",
              icon_color: "grey",
              coin: "R$ ",
            },
          ];
          const typeInvoices = [
            {
              name: "Total faturas",
              slug_invoice: "total",
              slug_percent: false,
              icon: "documentReport",
              icon_color: "blue",
            },
            {
              name: "Pagas",
              slug_invoice: "paid",
              slug_percent: "paid",
              icon: "checkReport",
              icon_color: "green",
            },
            {
              name: "Abertas",
              slug_invoice: "open",
              slug_percent: "open",
              icon: "lessReport",
              icon_color: "yellow",
            },
            {
              name: "Não pagas",
              slug_invoice: "unpaid",
              slug_percent: "unpaid",
              icon: "lessReport",
              icon_color: "yellow",
            },
            {
              name: "Reembolsadas",
              slug_invoice: "refunded",
              slug_percent: "refunded",
              icon: "earnDown",
              icon_color: "red",
            },
            {
              name: "Chargedbacks",
              slug_invoice: "chargedback",
              slug_percent: "chargedback",
              icon: "cardReport",
              icon_color: "grey",
            },
          ];
          let earns = [];
          let invoices = [];
          typeEarns.map((type) => {
            earns.push({
              ...type,
              value: response.incomes[type.slug_income]
                ? response.incomes[type.slug_income]
                : 0,
            });
          });
          typeInvoices.map((type) => {
            invoices.push({
              ...type,
              value: response.invoices.count[type.slug_invoice],
              percent: type.slug_percent
                ? response.invoices.percent[type.slug_invoice]
                : false,
            });
          });
          this.earnReport = earns;
          this.invoiceReport = invoices;
          this.errorReport = false;
        })
        .catch((e) => {
          this.errorReport = true;
        })
        .finally(() => {
          this.loading_reports = false;
        });
    },
    getIconMethod(method) {
      return iconMethod(method);
    },
    getMethod(method) {
      return textMethod(method);
    },
    formatTel(tel) {
      if (tel) {
        if (tel.toString().indexOf("+55") > -1) {
          var tell = tel.replace(/[() -]/g, "");
          if (tell[0] === "+") {
            tell = tell.substring(1);
          }
          return `https://api.whatsapp.com/send?phone=${tell}`;
        } else {
          return `https://api.whatsapp.com/send?phone=55${tel.replace(
            /[() -]/g,
            ""
          )}`;
        }
      }
    },
    getStatus(status) {
      let r = {};
      if (status) {
        switch (status.toLowerCase()) {
          case "processing": {
            r = { text: "Processando", class: "is-light-blue" };
            break;
          }
          case "authorized": {
            r = { text: "Transação autorizada", class: "is-light-blue" };
            break;
          }
          case "paid": {
            r = {
              text: "Pago",
              class: "is-green",
            };
            break;
          }
          case "refunded": {
            r = { text: "Estornado", class: "is-red" };
            break;
          }

          case "waiting_payment": {
            r = { text: "Aguardando pagamento", class: "is-warning" };
            break;
          }
          case "pending_approbation": {
            r = { text: "Liberação manual", class: "is-light-blue" };
            break;
          }

          case "pending_refund":
          case "refund_pending": {
            r = { text: "Reembolso pendente", class: "is-warning" };
            break;
          }
          case "refused": {
            r = { text: "Rejeitado", class: "is-red" };
            break;
          }
          case "chargedback": {
            r = { text: "Estorno", class: "is-warning" };
            break;
          }
          case "delayed": {
            r = { text: "Em processamento", class: "is-warning" };
            break;
          }
          default: {
            r = { text: status, class: "is-black" };
            break;
          }
        }
        return r;
      } else return { text: status, class: "is-black" };
    },
    handleResize() {
      // this.client.width = window.innerWidth;
      this.client.width = window.innerWidth;
    },

    changedType() {
      // chamando dessa forma para nao
      // passar o event como parametro
      this.fetchSales(this.search);
    },
    debounce: _.debounce(function() {
      this.fetchSales(this.search);
    }, 500),

    getCoSellerAmount(item) {
      if (item.product.co_sellers) {
        for (let i = 0; i < item.product.co_sellers.length; i++) {
          if (item.product.co_sellers[i].user_id == this.userID) {
            const percentage = item.product.co_sellers[i].percentage;
            return ((item.amount - item.fee) * percentage) / 100;
          }
        }
      }

      return 0;
    },

    openPanel(sale_id) {
      this.sale_id = sale_id;
      this.$router.push({ query: { s_id: this.sale_id } }).catch(() => {});

      setTimeout(() => {
        this.$refs.salePreview.openPanel(this.sale_id);
        this.loading = false;
      });
    },
    openFilters() {
      this.$bvModal.show("sales-filters");
    },
    exportData() {
      this.$bvModal.show("sales-export");
      // this.fetchSales("", true);
    },

    toPage(page) {
      this.pagination.currentPage = page;
      this.fetchSales(this.search);
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;

      this.fetchSales(this.search);
    },
    filter(filters) {
      this.filters = filters;
      this.pagination.currentPage = 1;
      this.fetchSales();
    },
    fetchSales(search = null, exportData = false, dados_local = null) {
      this.loading = true;
      this.pagination.totalPages = 1;

      if (this.buttonReportStatus === "black") {
        this.fetchReports(search);
      }

      let data = {};
      if (dados_local) {
        data = dados_local;
      } else {
        data = {
          page: this.pagination.currentPage,
          per_page: this.pagination.perPage,
          type: this.type_date,
          status: ["paid", "waiting_payment"],
        };
      }

      if (!exportData) {
        // reset
        this.pagination.totalPages = 1;
        this.sales = [];
        this.total = 0;
        this.itens = 0;
      }

      if (search != null && typeof search == "string" && search.length > 0) {
        data.search = search;
      }

      if (this.dateRange.startDate != null && this.dateRange.endDate != null) {
        data.date_start = moment
          .tz(this.dateRange.startDate, "America/Sao_Paulo")
          .format("YYYY-MM-DDT00:00:00-03:00");
        data.date_end = moment
          .tz(this.dateRange.endDate, "America/Sao_Paulo")
          .format("YYYY-MM-DDT23:59:59-03:00");
      }

      Object.keys(this.filters).forEach(
        (key) =>
          (this.filters[key] == null || this.filters[key].length === 0) &&
          delete this.filters[key]
      );
      data = Object.assign(data, this.filters);

      this.exportFilter = data;

      service
        .search(data)
        .then((response) => {
          // salvando pesquisa no localStorage
          this.saveSearch(data);
          this.sales = response.data.data;
          this.pagination.totalPages = response.data.last_page;
          this.total = response.total;
          this.itens = response.data.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getMetas(metas) {
      var result = [];
      if (metas.length) {
        metas.forEach((element) => {
          result.push(element.meta_key + "=" + element.meta_value);
        });
      }

      return result.join("&");
    },

    searchByFilter(dados_filter) {
      this.type_date = dados_filter.type;
      this.dateRange.startDate = this.decodeDateFilter(dados_filter.date_start);
      this.dateRange.endDate = this.decodeDateFilter(dados_filter.date_end);
      this.fetchSales(null, false, dados_filter);
    },
    decodeDateFilter(date) {
      switch (date) {
        // hoje
        case "hoje": {
          return moment();
        }
        // ontem
        case "ontem": {
          return moment().subtract(1, "days");
        }
        // uma semana atras
        case "uma semana": {
          return moment().subtract(7, "days");
        }
        // primeiro dia do mes atual
        case "primeiro dia mes atual": {
          return moment().startOf("month");
        }
        // ultimo dia do mes atual
        case "ultimo dia mes atual": {
          return moment().endOf("month");
        }
        // primeiro dia do ANO atual
        case "primeiro dia ano": {
          return moment().startOf("year");
        }
        // ultimo dia do ANO atual
        case "ultimo dia ano": {
          return moment().endOf("year");
        }

        // primeiro dia do mes passado
        case "primeiro dia mes passado": {
          return moment()
            .startOf("month")
            .subtract(1, "month");
        }
        // ultimo dia do mes passado
        case "ultimo dia mes passado":
          return moment()
            .endOf("month")
            .subtract(1, "month")
            .add(1, "days");
        default: {
          return date;
        }
      }
    },
    saveSearch(dados_pesquisa) {
      window.localStorage.sales_filters = {};
      dados_pesquisa.date_start = this.formatDateFilter(
        dados_pesquisa.date_start
      );
      dados_pesquisa.date_end = this.formatDateFilter(dados_pesquisa.date_end);
      window.localStorage.sales_filters = JSON.stringify(dados_pesquisa);
    },
    formatDateFilter(date) {
      switch (date) {
        // hoje
        case moment().format("YYYY-MM-DD"): {
          return "hoje";
        }
        // ontem
        case moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD"): {
          return "ontem";
        }
        // uma semana atras
        case moment()
          .subtract(7, "days")
          .format("YYYY-MM-DD"): {
          return "uma semana";
        }
        // primeiro dia do mes atual
        case moment()
          .startOf("month")
          .format("YYYY-MM-DD"): {
          return "primeiro dia mes atual";
        }
        // ultimo dia do mes atual
        case moment()
          .endOf("month")
          .format("YYYY-MM-DD"): {
          return "ultimo dia mes atual";
        }
        // primeiro dia do ANO atual
        case moment()
          .startOf("year")
          .format("YYYY-MM-DD"): {
          return "primeiro dia ano";
        }
        // ultimo dia do ANO atual
        case moment()
          .endOf("year")
          .format("YYYY-MM-DD"): {
          return "ultimo dia ano";
        }

        // primeiro dia do mes passado
        case moment()
          .startOf("month")
          .subtract(1, "month")
          .format("YYYY-MM-DD"): {
          return "primeiro dia mes passado";
        }
        // ultimo dia do mes passado
        case moment()
          .endOf("month")
          .subtract(1, "month")
          .format("YYYY-MM-DD"):
        case moment()
          .endOf("month")
          .subtract(1, "month")
          .add(1, "days")
          .format("YYYY-MM-DD"): {
          return "ultimo dia mes passado";
        }
        default: {
          return date;
        }
      }
    },
    getPermissions() {
      if (this.$store.getters.setPanel) {
        var dados = Cookies.get("setPanelPermission");
        if (dados) {
          dados = JSON.parse(dados);
          if (!dados.find((x) => x.name === "DashboardRead")) {
            if (!dados.find((x) => x.name === "SaleRead")) {
              this.$router.push("/cupom");
            }
          }
          if (dados.find((x) => x.name === "DashboardRead")) {
            if (!dados.find((x) => x.name === "SaleRead")) {
              this.$router.push("/dashboard");
            }
          }
        }
      }
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  mounted() {
    this.getPermissions();
    this.loading = true;
    // filtros salvos
    if (window.localStorage.sales_filters) {
      this.searchByFilter(JSON.parse(window.localStorage.sales_filters));
    } else {
      this.fetchSales();
    }

    // abrindo venda unica
    if (typeof this.$route.query.s_id != "undefined") {
      this.sale_id = this.$route.query.s_id;
      setTimeout(() => {
        this.$refs.salePreview.openPanel(this.sale_id);
        this.loading = false;
      }, 1000);
    }
  },
};
</script>

<style lang="scss" scoped>
.metas {
  list-style: none;
  font-size: 11px;

  li {
    word-break: break-all;
  }
}
.btn-table {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: rgba(237, 237, 240, 0.5);
  outline: none;
}
.btn-table:active {
  background: rgba(237, 237, 240, 0.5);
  border: none;
}
.btn-table img {
  filter: invert(50%);
}
.btn-table:first-child {
  background: rgba(0, 33, 99, 0.1);
}
.btn-table:first-child:active {
  background: rgba(0, 33, 99, 0.1);
  border: none;
}
.btn-table:first-child img {
  filter: invert(0);
}
.btn-table:nth-child(2) {
  margin-left: 15px;
  margin-right: 20px;
}
.header-table {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  padding-top: 15px;
}
.flex-sale {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.datas .vue-daterange-picker {
  width: 177px;
  font-size: 14px !important;
  color: #81858e !important;
}
.reset-data {
  font-size: 14px;
  color: #81858e;
  font-weight: normal;
  margin-left: 15px;
}
.input-busca {
  width: 350px;
  height: 60px !important;
  background: #ffffff;
  /* box-shadow: 0px 4px 8px rgba(30, 60, 90, 0.1); */
  border-radius: 5px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Montserrat;
  font-weight: normal;
  color: #81858e;
  font-size: 14px;
  transition: 0.5s;
}
.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}

.search {
  position: absolute;
  left: 52px;
  top: 22px;
  width: 14.7px;
  height: 14.7px;
  z-index: 999;
}
@media screen and (max-width: 1300px) {
  .search {
    left: 30px;
  }
}
.container-pesquisa {
  text-align: right;
  z-index: 1;
}
.btn-estatistica {
  margin-left: -30px;
}

.grid-report {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
}
@media screen and (max-width: 768px) {
  .header-table {
    display: block;
    padding: 0;
  }
  .flex-sale {
    display: block;
  }
  .flex-sale div {
    margin-left: 0;
  }
  .flex-acoes {
    margin: 15px 0;
  }
  .container-pesquisa {
    text-align: left;
  }
  .search {
    left: 20px;
  }
  .input-busca {
    width: 100%;
  }
  .btn-estatistica {
    margin-left: 0;
    margin-bottom: 20px;
  }

  .grid-report {
    grid-template-columns: 1fr 1fr;
  }
}
.dados-cliente div {
  margin-bottom: 3px;
}
.dados-cliente div:nth-child(2) {
  font-weight: normal;
  font-size: 13px;
}

.offer-name {
  font-weight: normal !important;
  font-size: 13px !important;
}

.whats-cliente {
  font-size: 13px;
  color: #002363;
}
.whats-cliente svg {
  margin-right: 10px;
}
.valor-venda {
  margin-left: 10px;
}

#id_venda {
  position: relative;
}
#id_venda::before {
  content: "";
  width: 15px;
  height: 15px;
  background: transparent;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: -25px;
  top: 0;
}
.TWO_CREDIT_CARDS {
  position: absolute;
  left: 5px;
  top: -5px;
}

// tipo data
#type {
  margin-bottom: 0 !important;
  margin-left: 10px;
}
.Table-body {
  padding: 15px;
}
.Table-body::before {
  left: 0;
}
.button {
  width: 179px;
  height: 55px;
  border-radius: 5px;
  background: #f7f7f7;
  color: #333;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button--black {
  background: #333;
  color: #fff;
}
.button:hover {
  transform: scale(1);
}
.inverted--black {
  filter: invert(100);
}
.title-report {
  color: #333;
  font-weight: bold;
  font-size: 20px;
}
.title-report-margin-top {
  margin-top: 41px;
}
.title-report-margin-bottom {
  margin-top: 41px;
}
</style>
